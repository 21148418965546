import { getDataByEnv } from '@/core/utils'

const customDataByEnv = {
  namespace: {
    production: '4ea9110d-1b68-497c-a4f1-6e10b5aeba72',
    development: '19d86320-c252-46b1-8af9-326ceb53d85a',
  },
}

export default {
  name: {
    nb: 'Ambulansefag',
  },
  slug: 'ambulansefag-vg2-3',
  namespace: getDataByEnv('namespace', customDataByEnv),
  analytics: {
    gtm: 'GTM-MMZS6SJ',
  },
  assets: {
    logo: '/themes/ambulansefag/images/logo.svg',
    favicon: '/themes/ambulansefag/favicon.ico',
  },
  dbok: {
    nb: [{ link: 'https://reader.dbok.no/#/' }],
    nn: [{ link: 'https://reader.dbok.no/#/' }],
  },
  styles: [
    '/themes/ambulansefag/css/ambulansefag.css',
    '/common/icons/icons.css',
    'https://cdn.jsdelivr.net/npm/katex@0.13.2/dist/katex.min.css',
  ],
  welcomeMessage: {
    nb: '<h1>Nettressursen for vg2–vg3 ambulansefag</h1><p>Læremiddelet er utgitt med støtte fra Utdanningsdirektoratet.</p>',
    nn: '<h1>Nettressursen for vg2–vg3 ambulansefag</h1><p>Læremiddelet er gitt ut med støtte frå Utdanningsdirektoratet.</p>',
  },
  langs: [
    {
      code: 'nb',
      label: 'Bokmål',
    },
    {
      code: 'nn',
      label: 'Nynorsk',
    },
  ],
  mainNav: {
    nb: [
      {
        title: 'Begreper',
        to: '/concepts/begreper',
        class: 'additional',
        productAccess: ['articles'],
      },
      {
        title: 'Yrkesoppgaver vg2',
        to: '/articles/yrkesoppgaver-vg2',
        class: 'additional',
        productAccess: ['articles'],
      },
      {
        title: 'Yrkesoppgaver vg3',
        to: '/articles/yrkesoppgaver-vg3',
        class: 'additional',
        productAccess: ['articles'],
      },
      {
        title: 'Multimedia',
        to: '/media/multimedia',
        class: 'additional',
        productAccess: ['articles'],
      },
      {
        title: 'Lærerens verktøykasse',
        to: '/files/laererens-verktoykasse',
        accessLevel: 'TEACHER',
        class: 'additional',
        productAccess: ['articles'],
      },
    ],
    nn: [
      {
        title: 'Omgrep',
        to: '/concepts/begreper',
        class: 'additional',
        productAccess: ['articles'],
      },
      {
        title: 'Yrkesoppgåver vg2',
        to: '/articles/yrkesoppgaver-vg2',
        class: 'additional',
        productAccess: ['articles'],
      },
      {
        title: 'Yrkesoppgåver vg3',
        to: '/articles/yrkesoppgaver-vg3',
        class: 'additional',
        productAccess: ['articles'],
      },
      {
        title: 'Multimedium',
        to: '/media/multimedia',
        class: 'additional',
        productAccess: ['articles'],
      },
      {
        title: 'Lærarens verktøykasse',
        to: '/files/laererens-verktoykasse',
        accessLevel: 'TEACHER',
        class: 'additional',
        productAccess: ['articles'],
      },
    ],
  },
  eportalProducts: [
    {
      id: 'a35236db-fb08-46e4-b5bf-1c54d68ae85a',
      product: 'articles',
    },
  ],
}
